:root{
  --component-hex: rgba(255, 101, 15, 1);
}
body{
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  width: 100%;
}
#root{
  overflow-x: hidden !important;
}
.App{
  min-height: 90vh;
  height: max-content;
  width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background: var(--component-hex);
}
