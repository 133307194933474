@media only screen and (max-width: 800px){
    .itemcard{
        width: 80vw !important;
        margin-bottom: 4vh;
    }
}

.itemcard{
    height: 30vh;
    width: 20vw;
    padding: 0 1vw;
    margin-right: 2vw;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    transition: 0.5s ease-out;
}
.itemcard:hover{
    background-color: var(--component-hex);
}