@media only screen and (max-width: 800px){
    .serviceMain{
        margin: 12vh 5vw !important;
    }
    .cardContainer{
        flex-direction: column;
    }
}


.serviceMain{
    min-height: 50vh;
    width: 100%;
    margin: 12vh 2vw;
}
.cardContainer, .serviceHeading{
    width: 100%;
    height: max-content;
}
.cardContainer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 5vh;
}
.serviceContent{
    width: 60%;
    padding: 5vh 0 2vh 13vw;
    color: rgba(0, 0, 0, 0.8);
}