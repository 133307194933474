@media only screen and (max-width: 800px){
    .footer{
        flex-direction: column;
        height: 80vh !important;
        padding: 5vh 5vw;
    }
    .footer > div:first-child {
        flex: 2 !important;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
    }
}

.footer{
    width: 100%;
    height: 40vh;
    background-color: black;
    color: white;
    display: flex;
}
.footer > div{
    flex: 1;
}

.footer > div:first-child {
    flex: 2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer > div:first-child > img{
    width: 70%;
}
.footer h1{
    font-size: 3vh;
    margin: 8vh 0 3vh 0;
}
.footer h6{
    color: white;
    font-size: 1.8vh;
    font-weight: normal;
    margin: 1.2vh 0;
}