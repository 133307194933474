@media only screen and (max-width: 700px) {
  .navLinks {
    display: none !important;
  }
  .logoContainer {
    margin-left: 4vw !important;
  }
  .logo {
    width: 30vw !important;
  }
}

.navMain {
  background-color: #fff;
  height: 10vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.07);
}
.logo {
  height: 7vh;
  width: 10vw;
}
.logoContainer {
  margin-left: 1vw;
}
.navLinks {
  display: flex;
  margin-right: 5vw;
}
.link {
  display: grid;
  place-items: center;
  font-size: 2.25vh;
  height: calc(10vh - 10px);
  min-width: 7vw;
  padding: 10px 10px 0;
  margin: 0 5px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: background-color 0.4s ease-out;
}
.link:hover {
  background-color: var(--component-hex);
  color: white;
}
