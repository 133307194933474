@media only screen and (max-width: 800px) {
  .homeMain {
    padding: 15vh 10vw 0 2vw !important;
  }
  .manageCarts {
    flex-direction: column;
  }
  .ourServices {
    flex-direction: column;
    height: max-content !important;
  }
  .serviceHeading > div {
    width: 20vw !important;
    margin-right: 3vw;
  }
  .ourServices > div:first-child > div:nth-child(2) {
    margin: 3vh 0 0 3vw !important;
  }
  .ourServices > div:first-child > button {
    margin: 5vh 0 4vh 3vw !important;
  }
  .about {
    flex-direction: column-reverse;
    height: max-content !important;
  }
  .about > div:last-child > div:nth-child(2),
  .about > div:last-child > div:last-child,
  .about > div:last-child > button {
    margin-right: 2vw !important;
  }
  .aboutHeading > div {
    width: 20vw !important;
  }
  .CarCont {
    flex-direction: column-reverse;
    height: max-content !important;
  }
  .carbar,
  .conbar {
    width: 14vw !important;
  }
}
.homeMain {
  padding: 15vh 2vw 0;
  /* height: max-content; */
}
.manageCarts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.ourServices {
  display: flex;
  width: 100%;
  height: 35vh;
  margin: 10vh 0;
  /* background-color: rgb(226, 150, 150); */
}
.ourServices > div:first-child {
  flex: 1;
}
.ourServices > div:last-child {
  flex: 1;
  font-size: 2.1vh;
  display: grid;
  place-items: center;
}
.ourServices > div:last-child > span {
  margin: 0 5vw 0 2vw;
  line-height: 3.5vh;
  color: rgba(0, 0, 0, 0.8);
}
.serviceHeading {
  display: flex;
  align-items: center;
}
.serviceHeading > div {
  background-color: var(--component-hex);
  height: 3vh;
  width: 10vw;
  margin-right: 3vw;
}
.ourServices > div:first-child > div:nth-child(2) {
  /* background-color: blue; */
  margin: 5vh 3vw 0 13vw;
  font-size: 2.7vh;
  font-weight: bolder;
}
.ourServices > div:first-child > button {
  margin: 5vh 0 0 13vw;
  height: 5vh;
  width: max-content;
  padding: 0 2vw;
  font-size: 2.1vh;
  font-weight: bold;
  background-color: var(--component-hex);
  color: white;
  border: none;
  cursor: pointer;
}

.about {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55vh;
}
.about > div:first-child {
  flex: 1;
}
.about > div:first-child > img {
  width: 100%;
}
.about > div:last-child {
  flex: 1;
  text-align: right;
  padding-left: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 100%;
}
.aboutHeading {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.aboutHeading > h1 {
  margin-right: 3vw;
}
.aboutHeading > div {
  height: 3vh;
  width: 10vw;
  background-color: var(--component-hex);
}
.about > div:last-child > div:nth-child(2) {
  margin-right: 13vw;
  font-size: 2.5vh;
  font-weight: bolder;
}
.aboutText {
  margin: 2vh 13vw 2vh 0;
  font-size: 2.1vh;
  line-height: 2.25vh;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}
.CarCont {
  height: 50vh;
  width: 100%;
  display: flex;
  margin: 10vh 0 2vh;
}
.career {
  padding: 10vh 5vh 10vh 0;
  flex: 4;
  display: flex;
}

.contact {
  padding: 5vh 0;
  flex: 1;
  display: flex;
}
.career {
  border: 3px solid var(--component-hex);
}
.contact {
  color: white;
  background-color: var(--component-hex);
  flex-direction: row-reverse;
}
.carbar,
.conbar {
  width: 10vw;
  height: 3vh;
}
.carbar {
  margin-right: 2vw;
  background-color: var(--component-hex);
}
.conbar {
  background-color: #fff;
  margin-left: 2vw;
}
.carContent,
.conContent {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.conContent {
  align-items: flex-end !important;
}
.content > h1 {
  margin: 0;
  font-size: 3vh;
}
.content > h6 {
  font-size: 2.1vh;
  font-weight: normal;
}
.content > div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.conContent > div {
  justify-content: flex-start !important;
}
.content button {
  height: 5vh;
  width: max-content;
  padding: 0 2vw;
  font-size: 2.1vh;
  font-weight: bold;
  border: none;
  cursor: pointer;
  margin: 0 1vw;
  background-color: var(--component-hex);
  color: white;
}
.conContent button {
  background-color: white;
  color: black;
}
