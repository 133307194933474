.Dropdown{
    /* --component-hex: rgba(255, 103, 15, 0.7); */
    position: absolute;
    height: 0vh;
    width: 20vh;
    transform: translate(-1.5vh, 8.5vh);
    /* background: var(--component-hex); */
    border: 2px solid var(--component-hex);
    background-color: #fff;
    
    overflow: hidden;
    opacity: 0;
    transition: 0.5s ease-in;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.dropActive{
    height: 26vh;
    opacity: 1;
}
.dropLink{
    margin: 0;
    font-weight: normal;

    flex: 1;
    height: 6vh;
    width: 20vh;
    color: black;
    font-size: 2vh; 
    display: grid;
    place-items: center;
    text-align: center;
    transition: 0.3s ease-in-out;
}
.dropLink:hover{
    background-color: var(--component-hex);
    color: white;
}