@media only screen and (min-width: 700px){
    .sidebarMain{
        display: none !important;
    }
}

.sidebarMain{
    background-color: #fff;
    position: fixed;
    height: 100vh;
    width: 10vw;
    top: 0px;
    right: 0;
    z-index: 52;
}
.sideLinks{
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.sidelink{
    min-width: 10vh;
    width: max-content;
    transform: rotate(90deg);
    padding: 10px ;
    text-align: center;
    transition: 0.5s;
}
.sidelink:hover{
    background-color: var(--component-hex);
}
.sidelink:nth-child(3), .sidelink:nth-child(4){
    margin: 2vh;
}