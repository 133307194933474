@media only screen and (max-width: 700px){
    .loginform{
        width: 75% !important;
        transform: translate(-6%, 0);
        padding: 5vw !important;
    }
    input{
        width: 100% !important;
    }
}

.login{
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.3);

    display: grid;
    place-items: center;
    z-index: 40;

    backdrop-filter: blur(4px);

}
.loginform{
    background-color: var(--component-hex);
    height: 60%;
    width: 25%;
    /* background-color: #fff; */

    padding: 2vh 1vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.loginhead{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginhead > *{
    color: white;
    margin: 0;
    font-size: 3vh;
    /* height: 5vh; */
}
.loginclose{
    cursor: pointer;
    transition: 0.5s ease;
    transform: scale(1.2);
    color: white;
}
.loginclose:hover{
    color: black;
}
.inputcontainer{
    height: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.inputcontainer input{
    width: 90%;
    line-height: 6vh;
    font-size: 1.8vh;
    padding: 0 4%;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.35s ease-in;
}
.inputcontainer input:focus, .inputcontainer input:hover{
    width: 90%;
    background-color: #fff;
}
.submitbutton{
    cursor: pointer;
    background-color: #fff;
    width: 90%;
    padding: 10;
}
input[type=submit]:hover{
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.loginform div:last-child{
    display: grid;
    place-items: center;
}
img{
    width: 60%;
}