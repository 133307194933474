@media only screen and (max-width: 800px){
    .manageItem{
        width: 80vw !important;
        height: 50vh !important;
    }
    .manageItem > div{
        position: absolute !important;
        height: 25vh;
        width: 30vw !important;
        transform: translate(20vw, 13vh) !important;
    }
}

.manageItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 23vw;    
    height: 60vh;
    margin-bottom: 10vh;
    cursor: pointer;
    color: black;
}
.manageItem > img{
    height: 40vh;
    width: 100%;
}
.manageItem > h1{
    width: 60%;
    height: 20vh;
    text-align: right; 
    font-size: 3.5vh;
    z-index: 2;
}
.manageItem > div{
    position: absolute;
    background-color: var(--component-hex);
    height: 25vh;
    width: 8vw;
    transform: translate(6vw, 15vh);
    z-index: 1;
}